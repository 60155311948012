import { Container, Navbar } from "react-bootstrap";
import Discord from "./Discord";


const NFThroneNavbar = () => {
  return (
    <Container fluid>
      <Navbar>
        <Container fluid>
          <Navbar.Brand href="/"> 👑 NFThrone </Navbar.Brand>
        </Container>
      </Navbar>
      <Discord />
    </Container>
  );
};

export default NFThroneNavbar;
