// Initialize Cloud Firestore through Firebase
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

/* REPLACE YOUR FIREBASE CONFIG HERE */
const firebaseConfig = {
  apiKey: "AIzaSyCoM3-rHwS5qf1fmOyBIo43B_Ccz_r4qA4",
  authDomain: "nfthingsapp.firebaseapp.com",
  projectId: "nfthingsapp",
  storageBucket: "nfthingsapp.appspot.com",
  messagingSenderId: "163541366528",
  appId: "1:163541366528:web:e550befa9cdbef744b25e5",
  measurementId: "G-JDN9C0R99J"
};

export var app = initializeApp(firebaseConfig);
export var DBFS = getFirestore();
