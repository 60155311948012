import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";

import axios from "axios";
import {writeOne, getVotes} from "../firebase/db.js";


const ASSET_ENDPOINT = "https://api.opensea.io/api/v1/assets";

const COLLECTIONS = ["cryptopunks", "boredapeyachtclub", "cool-cats-nft", "meebits"];

const NFThrone = () => {
  const [loading, setLoading] = useState(true);
  const [randNum1, setRandNum1] = useState();
  const [randNum2, setRandNum2] = useState();
  const [votes1, setVotes1] = useState();
  const [votes2, setVotes2] = useState();


  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  async function getNFT() {
    const os_options = {
      method: "GET",
      url: ASSET_ENDPOINT,
      params: {
        collection: COLLECTIONS[getRandomInt(0, COLLECTIONS.length - 1)],
        order_direction: "desc",
        offset: getRandomInt(0, 9999),
        limit: "1",
      },
    };

    return await axios
      .request(os_options)
      .then(function (response) {
        const data = {
          id: response.data.assets[0].collection.slug + "_" + response.data.assets[0].token_id,
          image_preview_url: response.data.assets[0].image_preview_url,
        }
        return data;
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  async function setupNFTs() {
    // console.log(getNFT());
    // console.log(getNFT());

    setRandNum1(await getNFT());
    setRandNum2(await getNFT());
    setVotes1(null);
    setVotes2(null);
    setLoading(false);
  }

  useEffect(() => {
    setupNFTs();
  }, []);

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const selectNft = async (firstAsset, secondAsset, order) => {
    console.log(firstAsset);
    if(order === 1){
      const data = await writeOne(
        firstAsset.id,
        "nfthrone"
      );
      console.log(data);

    } else {
      const data = await writeOne(
        secondAsset.id,
        "nfthrone"
      );
      console.log(data);

    }

    setVotes1(await getVotes(firstAsset.id));
    setVotes2(await getVotes(secondAsset.id))
    await sleep(1000);
    setLoading(true);
    setupNFTs();
  };

  return loading ? (
    <Container style={{ marginTop: "25px" }}>
      <Row>
        <Col style={{ textAlign: "center" }}>
          <h1 style={{ color: "goldenrod", fontWeight: "bold", fontSize: "400%" }}>
            {" "}
            Which do you elect to the NFThrone?{" "}
          </h1>
          <h2> Click on the NFT you like more </h2>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col style={{ textAlign: "center" }}>
          <Spinner animation="border" variant="warning" />
        </Col>
      </Row>
    </Container>
  ) : (
    <Container style={{ marginTop: "25px" }}>
      <Row>
        <Col style={{ textAlign: "center" }}>
          <h1 style={{ color: "goldenrod", fontWeight: "bold", fontSize: "400%" }}>
            {" "}
            Which do you elect to the NFThrone?{" "}
          </h1>
          <h2> Click on the NFT you like more </h2>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col md={1}></Col>
        <Col md={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Row>
            <Button
              variant={"light"}
              onClick={() => selectNft(randNum1,randNum2,1)}
              active
            >
              <img
                className="art"
                src={randNum1?.image_preview_url}
                style={{
                  maxWidth: "300px",
                  height: "auto",
                }}
                alt="nft"
              />
              <br />
              <br />
              <h1>Upvotes: {votes1} </h1>
            </Button>
          </Row>
        </Col>
        <Col md={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Row>
            <h1 style={{ fontSize: "800%" }}> 👑</h1>
          </Row>
        </Col>

        <Col md={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Row>
            <Button
              variant={"light"}
              onClick={() => selectNft(randNum1,randNum2,2)}
              active
            >
              <img
                className="art"
                src={randNum2?.image_preview_url}
                style={{
                  maxWidth: "300px",
                  height: "auto",
                }}
                alt="nft"
              />
              <br />
              <br />
              <h1>Upvotes: {votes2} </h1>
            </Button>
          </Row>
        </Col>
        <Col md={1}></Col>
      </Row>
      <br />
      <br />
    </Container>
  );
};

export default NFThrone;
