import "./styles/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { useEffect } from "react";

import NFThrone from "./views/NFThrone.js";
import NFThroneNavbar from "./views/NFThroneNavbar.js";
import { Mixpanel } from "./util/Mixpanel";

function App() {
  useEffect(() => {
    Mixpanel.track("NFThrone visited");
  }, []);

  return (
    <body>
      <NFThroneNavbar />
      <NFThrone />
    </body>
  );
}

export default App;
